export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000'
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || 'auction.holmasto.fi'
export const WEBSHOP_BASE_URL = process.env.REACT_APP_WEBSHOP_BASE_URL || 'http://holmasto.ddev.site'
export const ENV = process.env.REACT_APP_ENV
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || 'holmasto-auction'

/* NOTE These are defined also in the functions, so if you change them here,
 * change them there too */
export const minimumRises = Object.freeze([
  { range: [0, 50], rise: 5 },
  { range: [50, 300], rise: 10 },
  { range: [300, 800], rise: 20 },
  { range: [800, 1500], rise: 50 },
  { range: [1500, 4000], rise: 100 },
  { range: [4000, 10000], rise: 200 },
  { range: [10000, 20000], rise: 500 },
  { range: [20000, 100000], rise: 1000 },
  { range: [100000], rise: 5000 },
])

export const mainMenuUrls = {
  fi: 'https://holmasto.fi/wp-json/menus/v1/menus/73',
  en: 'https://holmasto.fi/wp-json/menus/v1/menus/348',
  sv: 'https://holmasto.fi/wp-json/menus/v1/menus/349',
}

export const missionURLs = {
  fi: 'https://holmasto.fi/wp-json/wp/v2/pages/57',
  en: 'https://holmasto.fi/wp-json/wp/v2/pages/4872',
  sv: 'https://holmasto.fi/wp-json/wp/v2/pages/4866',
}

export const footerMenuUrls = {
  fi: 'https://holmasto.fi/wp-json/menus/v1/menus/72',
  en: 'https://holmasto.fi/wp-json/menus/v1/menus/353',
  sv: 'https://holmasto.fi/wp-json/menus/v1/menus/352',
}

export const footerSEOMenuUrls = {
  fi: 'https://holmasto.fi/wp-json/menus/v1/menus/138626',
  en: 'https://holmasto.fi/wp-json/menus/v1/menus/138627',
  sv: 'https://holmasto.fi/wp-json/menus/v1/menus/138628',
}

export const articlesUrl = {
  fi: 'https://holmasto.fi/wp-json/wp/v2/posts?status=publish&lang=fi&categories=14048',
  en: 'https://holmasto.fi/wp-json/wp/v2/posts?status=publish&lang=en',
  sv: 'https://holmasto.fi/wp-json/wp/v2/posts?status=publish&lang=sv',
}

export const contactUrls = {
  en: 'https://www.holmasto.fi/en/contact-information',
  sv: 'https://www.holmasto.fi/sv/kontaktinformation',
  fi: 'https://www.holmasto.fi/yhteystiedot',
}

export const tosUrls = {
  en: 'https://www.holmasto.fi/en/order-and-delivery-terms/',
  sv: 'https://www.holmasto.fi/sv/kop-och-leveransvillkor/',
  fi: 'https://www.holmasto.fi/tilaus-ja-toimitusehdot/',
}

export const allowedCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'NO',
  'CH',
  'IS',
  'LI',
]

export const getLanguageOptions = t => [
  {
    value: 'fi',
    label: t('finnish'),
  },
  {
    value: 'en',
    label: t('english'),
  },
  {
    value: 'sv',
    label: t('swedish'),
  },
]
